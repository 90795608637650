// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
require.context('../../assets/images', true);

import 'bootstrap';
import './src/application.scss';
import select2 from 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';
import 'easy-autocomplete/dist/jquery.easy-autocomplete';

import * as THREE from './lib/3js/three.js';
window.THREE = THREE;
require('./lib/3js/OBJLoader.js');
require('./lib/3js/OrbitControls.js');
require('./lib/jquery.konami.min.js');

require("trix")
require("@rails/actiontext")